<template>
  <header
    :class="[
      'layout-header',
      {
        'layout-header--with-gaps': isHeaderWithGaps
      }]"
  >
    <div class="layout-header__wrapper">
      <esmp-sidebar-toggle-button
        v-if="!isHeaderWithGaps && windowSizes.isPhonePortrait"
        v-model="isCollapsedMenuLocal"
      />

      <layout-breadcrumbs v-if="!isHeaderWithGaps" />
      <current-portal-logo
        v-if="isHeaderWithGaps && currentPortal"
        :current-portal="currentPortal"
      />
      <layout-logo v-if="isHeaderWithGaps || !windowSizes.isPhonePortrait" />
    </div>
  </header>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

const LayoutBreadcrumbs = () => import('@/layouts/breadcrumbs');
const LayoutLogo = () => import('@/layouts/logo');
const CurrentPortalLogo = () => import('@/layouts/current-portal-logo');

export default {
  name: 'LayoutHeader',

  components: { LayoutBreadcrumbs, LayoutLogo, CurrentPortalLogo },

  computed: {
    ...mapState('viewOptions', ['windowSizes']),
    ...mapState('sidebar', ['isCollapsedMenu']),
    ...mapGetters('system', ['currentPortal']),
    isHeaderWithGaps() {
      return [
        'Login',
        'Error',
        'Error404',
        'ForgetPassword',
        'ResetPassword',
        'Maintenance'].includes(this.$route.name);
    },

    isCollapsedMenuLocal: {
      get() {
        return this.isCollapsedMenu;
      },

      set(value) {
        this.collapseMenu(value);
      },
    },
  },

  methods: {
    ...mapActions('sidebar', ['collapseMenu']),
  },
};
</script>

<style lang="scss">
.layout-header {
  $parent: &;
  background-color: $color-grayscale-05;
  height: $header-height;
  flex-shrink: 0;
  position: relative;
  z-index: 10;

  &__wrapper {
    height: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &--with-gaps {
    padding: 0 24px;
    box-shadow: inset 0px -1px 0px #e5e5e5;
  }

  @include for-size(phone-portrait-down) {
    &:not(#{$parent}--with-gaps) #{$parent}__wrapper {
      position: fixed;
      top: 0;
      left: $header-height;
      width: calc(100% - #{$header-height});
      padding: 0 20px;
      background-color: $color-white;
      box-shadow: $base-shadow-m;

      .esmp-sidebar-toggle-button {
        position: fixed;
        top: 0;
        left: 0;
        box-shadow: $base-shadow-m;
      }
    }
  }
}
</style>
